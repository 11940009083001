<template>
  <div class="container-sm">
    <br><br><br><br><br>
    <router-link
      :to="{ name: 'deposit' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center mr-1">
          <i class="fas fa-long-arrow-left" />
        </div>
        <h5 class="text-center font-weight-bolder m-0">
          ฝากเครดิต
        </h5>
      </div>
    </router-link>

    <div class="card p-1 mt-1">
      <div class="text-center header-dark">
        <h5 class="font-weight-bolder m-0">
          โอนแบบทรูมันนี่ วอลเล็ต
        </h5>
      </div>
      <div class="text-center">
        <small
          class="text-danger"
        ><u>"กรุณาใช้บัญชีที่ท่านสมัครโอนมาเท่านั้น"</u></small><br>
        <small
          class="text-yellow"
        >*ระบบจะเติมเครดิตเกมอัตโนมัติ ภายในเวลา 1-2 นาที</small>
      </div>

      <div
        style="margin: auto"
        class="
          text-white text-center
          justify-content-center
          box-deposit
          p-1
          border-10
          mt-1
        "
      >
        <div class="fs-16 font-weight-600 text-color">
          <span> เติมเงินขั้นต่ำ 1 บาท </span>
        </div>
      </div>

      <div class="card-bank mt-1 p-1">
        <div class="d-flex align-items-center">
          <div>
            <img
              src="/bankIcons/true.svg"
              alt="bank"
              height="35"
            >
          </div>
          <div>
            <h5
              class="m-0 font-weight-bolder"
              style="color: #000"
            >
              ทรูมันนี่ วอลเล็ต
            </h5>
          </div>
        </div>

        <div class="mt-1 mb-1 d-flex justify-content-between">
          <img
            src="/chip.png"
            alt="chip"
            height="40"
          >

          <button
            v-clipboard:copy="agent.truemoney_acc"
            class="btn btn-copy"
            @click="copy"
          >
            <i class="fas fa-copy" /> คัดลอกเบอร์
          </button>
        </div>

        <div>
          <div class="box-bank">
            <h4 class="m-0 dark font-weight-bolder">
              {{ agent.truemoney_acc }}
            </h4>
          </div>
          <div>
            <h5 class="m-0 dark">
              {{ agent.truemoney_name }}
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="text-left text-head-hisotry mt-2">
      ประวัติการเติมเครดิต [รายการล่าสุด 5 รายการ]
    </div>

    <div
      class="
        w-100
        border-solid
        rounded-lg
        text-color
        bg-table
        border-10
        table-responsive
      "
    >
      <table
        role="table"
        aria-busy="false"
        aria-colcount="3"
        class="table b-table table-sm"
      >
        <thead role="rowgroup">
          <tr role="row">
            <th
              role="columnheader"
              scope="col"
              aria-colindex="1"
              class="small text-left pl-1"
            >
              <div>วันที่/เวลา</div>
            </th>
            <th
              role="columnheader"
              scope="col"
              aria-colindex="2"
              class="small text-center pr-1"
            >
              <div>จำนวนเงิน</div>
            </th>
            <th
              role="columnheader"
              scope="col"
              aria-colindex="3"
              class="small text-center pr-1"
            >
              <div>สถานะ</div>
            </th>
          </tr>
        </thead>
        <tbody
          v-if="data.length > 0"
          role="rowgroup"
        >
          <tr
            v-for="item in data"
            :key="item.id"
            role="row"
            class="b-table-empty-row"
          >
            <td>{{ item.date }}</td>
            <td class="text-center">
              {{ item.amount }}
            </td>
            <td class="text-center">
              <span
                v-if="item.status === 'success'"
                class="text-success"
              >{{
                item.status
              }}</span>
              <span
                v-else-if="item.status === 'waiting'"
                class="text-warning"
              >{{ item.status }}</span>
              <span
                v-else
                class="text-danger"
              >{{ item.status }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            role="row"
            class="b-table-empty-row"
          >
            <td
              colspan="3"
              role="cell"
            >
              <div
                role="alert"
                aria-live="polite"
              >
                <div class="text-color text-center pt-2 pb-2 fs-14">
                   ไม่พบข้อมูล
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line
    UserProfile: Object,
  },
  data() {
    return {
      message: '0612345678',
      data: [],
      agent: {},
    }
  },
  mounted() {
    this.GetAgentData()
  },
  methods: {
    GetAgentData() {
      this.$http
        .get('/agent/show')
        .then(response => {
          // console.log(response.data.ListGames)
          this.agent = response.data
        })
        .catch(error => console.log(error))
    },
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกเลขบัญชีสำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
  },
}
</script>

<style scoped>

table {
  width: 100%;
  border-collapse: collapse;
}
tbody tr {
  padding-left: 5px !important;
}
.btn-copy {
  background: linear-gradient(90deg, #494949 0%, #000000 100%);
  color: #fff;
  border: none;
  border-radius: 6px;
  height: 35px;
  padding: 2px 10px;
}
.btn-copy:hover {
  box-shadow: 0 0 0 5px rgba(73, 73, 73, 0.2);
}
.card-bank {
  min-height: 175px;
  border-radius: 14px;
  /* background-image: url('/bg-bank2.svg'); */
  background: linear-gradient(
      0deg,
      rgba(185, 153, 91, 0.9),
      rgba(221, 204, 151, 0.9),
      rgba(185, 153, 91, 0.9)
    ),
    url("/bg-bank2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 6px;
  padding: 10px;
}
.dark {
  color: #000 !important;
}
.header-dark {
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  padding: 10px;
}
.card-list {
  margin: 0 15px;
  background: #2e2e2e;
  box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 10px;
}

.card {
  border-radius: 14px;
  border: 1px solid rgb(51, 51, 51);
  background: #232323 !important;
  color: rgb(255, 255, 255) !important;
}
.text-yellow {
  color: #ffc107 !important;
}
.bg-balance {
  background-image: url(https://www.ltobet.com/assets/img/bg-banner-home.svg) !important;
  background-repeat: repeat !important;
  background-size: 100% auto !important;
}
.btn-withdraw {
  height: 36px;
  background-color: #ffffff27;
  color: #fff !important;
  border: 1px solid #fff;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.btn-depo {
  height: 36px;
  background: #fff;
  border: none;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.theme-ltobet .book_bank_content .bank-icon .media-icon {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  border-radius: 4px;
  position: relative;
}
.btn-orange {
  background-image: linear-gradient(103deg, #0c2574 1%, #341082);
  width: 100%;
  border-radius: 10px !important;
  color: #ffffff !important;
}
.box-ac-items {
  padding: 10px;
  border-radius: 5px;
  border: #000 1px solid;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
</style>
